import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  item: {
    padding: theme.spacing(0.5),
  },
  paper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  link: {
    textDecoration: "none",
  },
  typo: {
    color: theme.palette.primary.light,
  },
}));

function NavCard({ name, url }) {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={6} sm={2} className={classes.item}>
        <Link to={url} className={classes.link}>
          <Paper variant="outlined" className={classes.paper}>
            <Typography align="center" className={classes.typo}>
              {name}
            </Typography>
          </Paper>
        </Link>
      </Grid>
    </>
  );
}

export default NavCard;
