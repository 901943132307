import { Button, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  btn: {
    marginTop: theme.spacing(0.5),
    borderRadius: 0,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      boxShadow: "none",
    },
  },
}));

function BtnLink({ path, name, customClassName, isActivate }) {
  const classes = useStyles();
  return (
    <Button
      component={Link}
      to={path}
      fullWidth
      variant={isActivate ? "contained" : "outlined"}
      color="primary"
      className={Boolean(customClassName) ? customClassName : classes.btn}
    >
      {name}
    </Button>
  );
}

export default BtnLink;
