import { handleResponse } from "./utils";

export const contentServices = {
  getAllContents,
  updateContentById,
  getContentById,
  createContent,
};

// public api
function getAllContents() {
  const requestOption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/get_all_contents`,
    requestOption
  ).then(handleResponse);
}

function updateContentById(token, id) {
  const requestOption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(id),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/update_content_by_id`,
    requestOption
  ).then(handleResponse);
}

function getContentById(id) {
  const requestOption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(id),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/get_content_by_id`,
    requestOption
  ).then(handleResponse);
}

function createContent(token, content) {
  const requestOption = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(content),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/create_content`,
    requestOption
  ).then(handleResponse);
}
