import { AppConfig } from "../AppConfig";
import { authConstants } from "../_constants";

export { login };

let res = JSON.parse(localStorage.getItem(AppConfig.loginSeed));
const initialState = res ? res : {};

function login(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        request: true,
      };
    case authConstants.LOGIN_SUCCESS:
      return {
        request: false,
        logged: true,
        res: action.res,
      };
    case authConstants.LOGIN_FAILURE:
      return {
        request: false,
      };
    case authConstants.LOGOUT:
      return {
        logged: false,
      };
    default:
      return state;
  }
}
