import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./_reducers";
import { AppConfig } from "./AppConfig";

const loggerMiddleware = createLogger();

export const store = createStore(
  rootReducer,
  AppConfig.isShowLogs
    ? applyMiddleware(thunkMiddleware, loggerMiddleware)
    : applyMiddleware(thunkMiddleware)
);
