import { Grid, makeStyles } from "@material-ui/core";
import PanelWrapper from "../components/PanelWrapper";
import BtnLink from "../components/BtnLink";
import { Navigate, Route, Routes } from "react-router-dom";
import ContentCreate from "./ContentManageSub/ContentCreate";
import ContentList from "./ContentManageSub/ContentList";
import { green, grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  gridSub: {
    paddingLeft: theme.spacing(1),
  },
  createBtn: {
    marginTop: theme.spacing(0.5),
    backgroundColor: green[400],
    color: grey[100],
    borderRadius: 0,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      boxShadow: "none",
      color: theme.palette.primary.main,
    },
  },
}));

function ContentManage() {
  const classes = useStyles();
  return (
    <PanelWrapper
      title="活动内容管理"
      child={
        <Grid container>
          <Grid item xs={2} className={classes.gridSub}>
            <BtnLink
              path="/content_manage/create"
              name="创建内容"
              customClassName={classes.createBtn}
            ></BtnLink>
            <BtnLink path="/content_manage" name="活动内容列表"></BtnLink>
            <BtnLink path="/" name="返回首页"></BtnLink>
          </Grid>
          <Grid item xs={10} className={classes.gridSub}>
            <Routes>
              <Route path="/" element={<ContentList></ContentList>}></Route>
              <Route
                path="/create"
                element={<ContentCreate></ContentCreate>}
              ></Route>
              <Route
                path="*"
                element={<Navigate to="/content_manage" replace></Navigate>}
              ></Route>
            </Routes>
          </Grid>
        </Grid>
      }
    ></PanelWrapper>
  );
}

export default ContentManage;
