export const AppConfig = {
  // ali cloud sms code service config
  // 您的AccessKey ID
  accessKeyId: "LTAI5tAecB7qJyXTKcs87Vom",
  // 您的AccessKey Secret
  accessKeySecret: "FSpjuoWXpe8MF9qGzEZejl2pKPiTj3",
  // 访问的区域
  regionId: "cn-qingdao",

  // login seed
  loginSeed: "uoWsd3ffdXpe8MF12314asdf2134rasd",
};
