import {
  Button,
  Grid,
  Paper,
  Typography,
  makeStyles,
  TextField,
  Switch,
} from "@material-ui/core";
import "braft-editor/dist/index.css";
import BraftEditor from "braft-editor";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(1),
  },
  input: {
    paddingLeft: theme.spacing(1),
    [`& fieldset`]: {
      borderRadius: 0,
    },
    minWidth: 500,
  },
  optionContainer: {
    marginBottom: theme.spacing(0.5),
  },
}));

function ContentCreate() {
  const classes = useStyles();

  const [editorState, setEditorState] = useState();
  const handleChangeAnnounce = (state) => {
    setEditorState(state);
  };

  const [activity, setActivity] = useState({
    title: "",
    cover: "",
    startTime: "",
    endTime: "",
    enterTime: "",
    maxCount: "",
    content: "",
    isReview: false,
    reviewUrl: "",
  });
  const handleChange = (prop) => (event) => {
    setActivity({ ...activity, [prop]: event.target.value });
  };

  const handleChecked = (prop) => (event) => {
    setActivity({ ...activity, [prop]: event.target.checked });
  };

  const handleUploadImg = () => {
    console.log("upload img");
  };

  const handleSaveClick = () => {
    console.log(editorState ? editorState.toHTML() : "");
    console.log(activity);
  };
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ paddingBottom: 10 }}
        >
          <Grid item>
            <Typography variant="h5" color="primary">
              活动和回顾创建工具
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveClick}
            >
              保存
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper variant="outlined" className={classes.paper}>
            <Grid
              container
              alignItems="center"
              className={classes.optionContainer}
            >
              <Grid item>
                <Typography className={classes.announceTitle}>
                  标题：
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  hiddenLabel
                  variant="outlined"
                  id="title"
                  size="small"
                  className={classes.input}
                  onChange={handleChange("title")}
                ></TextField>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              className={classes.optionContainer}
            >
              <Grid item>
                <Typography className={classes.announceTitle}>
                  上传封面图片：
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" component="label">
                  <input
                    type="file"
                    accept=".jpg, .png"
                    hidden
                    onChange={handleUploadImg}
                  />
                  选择本地图片
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              className={classes.optionContainer}
            >
              <Grid item>
                <Typography className={classes.announceTitle}>
                  是否活动回顾？
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={activity.isReview}
                  onChange={handleChecked("isReview")}
                  color="primary"
                ></Switch>
              </Grid>
            </Grid>

            {!activity.isReview && (
              <>
                <Grid
                  container
                  alignItems="center"
                  className={classes.optionContainer}
                >
                  <Grid item>
                    <Typography className={classes.announceTitle}>
                      开始日期：
                    </Typography>
                  </Grid>
                  <Grid item>
                    <DatePicker
                      selected={activity.startTime}
                      onChange={(date) =>
                        setActivity({ ...activity, startTime: date })
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  alignItems="center"
                  className={classes.optionContainer}
                >
                  <Grid item>
                    <Typography className={classes.announceTitle}>
                      结束日期：
                    </Typography>
                  </Grid>
                  <Grid item>
                    <DatePicker
                      selected={activity.endTime}
                      onChange={(date) =>
                        setActivity({ ...activity, endTime: date })
                      }
                    />
                  </Grid>
                </Grid>
              </>
            )}

            {activity.isReview ? (
              <>
                <Grid
                  container
                  alignItems="center"
                  className={classes.optionContainer}
                >
                  <Grid item>
                    <Typography className={classes.announceTitle}>
                      回顾文章链接：
                    </Typography>
                  </Grid>
                  <Grid item>
                    <TextField
                      fullWidth
                      hiddenLabel
                      variant="outlined"
                      id="title"
                      size="small"
                      className={classes.input}
                      onChange={handleChange("reviewUrl")}
                    ></TextField>
                  </Grid>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Paper>
        </Grid>
        {!activity.isReview && (
          <Grid item xs={12}>
            <BraftEditor value={editorState} onChange={handleChangeAnnounce} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ContentCreate;
