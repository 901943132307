import { connect, useDispatch } from "react-redux";
import {
  Typography,
  makeStyles,
  Grid,
  Dialog,
  DialogTitle,
  Button,
  Divider,
} from "@material-ui/core";
import { generalActions } from "../_actions";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ErrorIcon from "@material-ui/icons/Error";

const useStyles = makeStyles((theme) => ({
  alertMsg: {
    padding: theme.spacing(4),
  },
  btnAlert: {
    minWidth: 120,
    margin: theme.spacing(1),
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      boxShadow: "none",
    },
  },
  iconSuccess: {
    display: "flex",
    color: theme.palette.success.main,
    marginLeft: theme.spacing(1),
  },
  iconError: {
    display: "flex",
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
}));

function AlertDialog({ alertControl }) {
  const classes = useStyles();
  const dispatcher = useDispatch();

  const handleCloseAlertClick = () => {
    if (alertControl.alertType === "CLOSE_AND_JUMP") {
      alertControl.callback();
      dispatcher(generalActions.alertControl("", ""));
    } else {
      dispatcher(generalActions.alertControl("", ""));
    }
  };
  return (
    <Dialog
      onClose={handleCloseAlertClick}
      open={Boolean(alertControl.shown)}
      PaperProps={{ style: { borderRadius: 0, minWidth: 600 } }}
    >
      <DialogTitle>
        {alertControl.alertType === "SUCCESS" && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>成功</Grid>
            <Grid item>
              <CheckCircleOutlineIcon
                className={classes.iconSuccess}
              ></CheckCircleOutlineIcon>
            </Grid>
          </Grid>
        )}
        {alertControl.alertType === "ERROR" && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>错误</Grid>
            <Grid item>
              <ErrorIcon className={classes.iconError}></ErrorIcon>
            </Grid>
          </Grid>
        )}
        {alertControl.alertType === "CLOSE_AND_JUMP" && (
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>错误</Grid>
            <Grid item>
              <ErrorIcon className={classes.iconError}></ErrorIcon>
            </Grid>
          </Grid>
        )}
      </DialogTitle>
      <Divider variant="fullWidth"></Divider>
      <Grid container justifyContent="center">
        <Grid item>
          <Typography className={classes.alertMsg}>
            {alertControl.msg}
          </Typography>
        </Grid>
      </Grid>
      <Grid container justifyContent="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={classes.btnAlert}
            onClick={handleCloseAlertClick}
          >
            确定
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}

function mapStateToProps(state) {
  const { alertControl } = state;
  return {
    alertControl,
  };
}

export default connect(mapStateToProps)(AlertDialog);
