import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  makeStyles,
  Grid,
  Typography,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Button,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    background: "transparent",
    borderColor: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(3),
    height: "100%",
  },
  helperText: {
    paddingTop: theme.spacing(2),
    fontSize: theme.title.fontSize,
  },
  input: {
    [`& fieldset`]: {
      borderRadius: 0,
    },
  },

  btnLogin: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(4),
    borderRadius: 0,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      boxShadow: "none",
    },
    fontSize: theme.btn.fontSize,
  },
  link: {
    fontSize: theme.typography.fontSize,
    color: theme.palette.primary.light,
  },
}));

function Login({ login }) {
  const classes = useStyles();

  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleLogin() {}

  useEffect(() => {}, []);

  return (
    <div style={{ paddingTop: 81 }}>
      <Grid container justifyContent="center">
        <Grid item xs={5}>
          <Paper variant="outlined" square className={classes.paper}>
            <Typography className={classes.helperText}>用户名</Typography>
            <TextField
              fullWidth
              hiddenLabel
              id="username"
              onChange={handleChange("username")}
              variant="outlined"
              className={classes.input}
            ></TextField>
            <Typography className={classes.helperText}>密码</Typography>
            <TextField
              fullWidth
              hiddenLabel
              id="password"
              onChange={handleChange("password")}
              type={showPassword ? "text" : "password"}
              variant="outlined"
              className={classes.input}
              InputProps={{
                // <-- This is where the toggle button is added.
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            ></TextField>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              className={classes.btnLogin}
              onClick={handleLogin}
              disabled={login.request === true}
            >
              登录
            </Button>

            <Grid
              container
              justifyContent={"center"}
              alignContent="center"
              alignItems="center"
              className={classes.linkContainer}
            >
              <Grid item>
                <Link to="/" className={classes.link}>
                  返回首页
                </Link>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

function mapStateToProps(state) {
  const { login } = state;

  return {
    login,
  };
}

export default connect(mapStateToProps)(Login);
