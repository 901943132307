import { contentConstants } from "../_constants";
import { contentServices } from "../_services/content.services";

export const contentActions = {
  getAllContents,
  updateContentById,
  getContentById,
  createContent,
};

function getAllContents() {
  return (dispatch) => {
    dispatch(request());

    contentServices.getAllContents().then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: contentConstants.GET_ALL_CONTENTS_REQUEST };
  }
  function success(res) {
    return { type: contentConstants.GET_ALL_CONTENTS_SUCCESS, res };
  }
  function failure(error) {
    return { type: contentConstants.GET_ALL_CONTENTS_FAILURE, error };
  }
}

function updateContentById(token, id) {
  return (dispatch) => {
    dispatch(request());

    contentServices.updateContentById(token, id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: contentConstants.UPDATE_CONTENT_BY_ID_REQUEST };
  }
  function success(res) {
    return { type: contentConstants.UPDATE_CONTENT_BY_ID_SUCCESS, res };
  }
  function failure(error) {
    return { type: contentConstants.UPDATE_CONTENT_BY_ID_FAILURE, error };
  }
}

function getContentById(id) {
  return (dispatch) => {
    dispatch(request());

    contentServices.getContentById(id).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: contentConstants.GET_CONTENT_BY_ID_REQUEST };
  }
  function success(res) {
    return { type: contentConstants.GET_CONTENT_BY_ID_SUCCESS, res };
  }
  function failure(error) {
    return { type: contentConstants.GET_CONTENT_BY_ID_FAILURE, error };
  }
}

function createContent(token, content) {
  return (dispatch) => {
    dispatch(request());

    contentServices.createContent(token, content).then(
      (res) => {
        dispatch(success(res));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

  function request() {
    return { type: contentConstants.CREATE_CONTENT_REQUEST };
  }
  function success(res) {
    return { type: contentConstants.CREATE_CONTENT_SUCCESS, res };
  }
  function failure(error) {
    return { type: contentConstants.CREATE_CONTENT_FAILURE, error };
  }
}
