export const contentConstants = {
  GET_ALL_CONTENTS_REQUEST: "GET_ALL_CONTENTS_REQUEST",
  GET_ALL_CONTENTS_SUCCESS: "GET_ALL_CONTENTS_SUCCESS",
  GET_ALL_CONTENTS_FAILURE: "GET_ALL_CONTENTS_FAILURE",

  UPDATE_CONTENT_BY_ID_REQUEST: "UPDATE_CONTENT_BY_ID_REQUEST",
  UPDATE_CONTENT_BY_ID_SUCCESS: "UPDATE_CONTENT_BY_ID_SUCCESS",
  UPDATE_CONTENT_BY_ID_FAILURE: "UPDATE_CONTENT_BY_ID_FAILURE",

  GET_CONTENT_BY_ID_REQUEST: "GET_CONTENT_BY_ID_REQUEST",
  GET_CONTENT_BY_ID_SUCCESS: "GET_CONTENT_BY_ID_SUCCESS",
  GET_CONTENT_BY_ID_FAILURE: "GET_CONTENT_BY_ID_FAILURE",

  CREATE_CONTENT_REQUEST: "CREATE_CONTENT_REQUEST",
  CREATE_CONTENT_SUCCESS: "CREATE_CONTENT_SUCCESS",
  CREATE_CONTENT_FAILURE: "CREATE_CONTENT_FAILURE",
};
