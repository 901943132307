import { connect } from "react-redux";
import { Grid, Typography, makeStyles } from "@material-ui/core";
import NavCard from "../components/NavCard";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "90vh",
  },
  paper: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    padding: theme.spacing(3),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
}));

function Main({ login }) {
  const classes = useStyles();
  return (
    <>
      {
        //login.res && login.logged
        true ? (
          <>
            <Grid container>
              <NavCard name="活动内容管理" url="/content_manage"></NavCard>
              <NavCard name="人员管理" url="/user_manage"></NavCard>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.container}
            >
              <Grid item>
                <img src="/static/qrcode.jpg" alt="qrcode"></img>
                <Typography component="p" variant="caption" align="center">
                  扫码查看“燕潮燕潮”小程序
                </Typography>
              </Grid>
            </Grid>
          </>
        )
      }
    </>
  );
}

function mapStateToProps(state) {
  const { login } = state;

  return {
    login,
  };
}

export default connect(mapStateToProps)(Main);
