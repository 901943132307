import { makeStyles, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginBottom: theme.spacing(5),
    marginTop: theme.spacing(1),
    background: "transparent",
    borderColor: theme.palette.primary.light,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  title: {
    fontSize: theme.title.fontSize,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textAlign: "start",
  },
}));

function PanelWrapper({ title, child }) {
  const classes = useStyles();
  return (
    <Paper variant="outlined" square className={classes.paper}>
      <Typography className={classes.title}>{title}</Typography>
      {child}
    </Paper>
  );
}

export default PanelWrapper;
