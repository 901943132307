import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
  },
}));

function ContentList() {
  const classes = useStyles();
  return (
    <Grid container className={classes.con}>
      <Grid item xs={12}>
        content list
      </Grid>
    </Grid>
  );
}
export default ContentList;
