export function logout() {
  localStorage.removeItem("user");
}

export function handleResponse(response) {
  return response.text().then((text) => {
    // text is html
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([400, 401, 403].includes(response.status)) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
