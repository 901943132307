import { generalConstants } from "../_constants";

export { alertControl };

function alertControl(state = {}, action) {
  switch (action.type) {
    case generalConstants.ALERT_SHOWN:
      return {
        shown: true,
        msg: action.msg,
        alertType: action.alertType,
        callback: action.callback,
      };
    case generalConstants.ALERT_UNSHOWN:
      return {
        shown: false,
        msg: action.msg,
        alertType: action.alertType,
        callback: action.callback,
      };
    default:
      return state;
  }
}
