import { AppConfig } from "../AppConfig";
import { authConstants } from "../_constants";
import { authServices } from "../_services/auth.servies";

export const authActions = {
  login,
  logout,
};

function login(username, password, navigate, callback) {
  return (dispatch) => {
    dispatch(request());

    authServices.login(username, password).then(
      (res, error) => {
        console.log(res, error);
        dispatch(success(res));
        localStorage.setItem(
          AppConfig.loginSeed,
          JSON.stringify({
            res: res,
            logged: true,
            request: false,
          })
        );
        navigate("/");
        callback(true);
      },
      (error) => {
        dispatch(failure(error.toString()));
        callback(false);
      }
    );
  };

  function request() {
    return { type: authConstants.LOGIN_REQUEST };
  }
  function success(res) {
    return { type: authConstants.LOGIN_SUCCESS, res };
  }
  function failure(error) {
    return { type: authConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  localStorage.removeItem(AppConfig.loginSeed);
  return { type: authConstants.LOGOUT };
}
