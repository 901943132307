import { createTheme } from "@material-ui/core";
import { green, grey, red } from "@material-ui/core/colors";

export const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#092765",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated from palette.primary.main,
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: "#ffff00",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated from palette.primary.main,
    },
    success: {
      main: green[500],
      contrastText: grey[100],
    },
    error: {
      main: red[500],
    },
    cancel: {
      main: grey[200],
      dark: grey[300],
      contrastText: grey[900],
    },
  },
  typography: {
    fontFamily: "FangZhengXiaoBiaoSongJianTi",
    fontSize: 14,
  },
  title: {
    fontFamily: "FangZhengXiaoBiaoSongJianTi",
    fontSize: 16,
  },
  btn: {
    fontSize: 16,
  },
});
