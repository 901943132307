import { Grid, Typography, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
  footerBar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  link: {
    textDecoration: "none",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      color: theme.palette.primary.light,
    },
  },
}));

function BottomFooter() {
  const classes = useStyle();

  return (
    <footer className="footer">
      <Grid container justifyContent="center" className={classes.footerBar}>
        <Grid item>
          <Typography variant="body2">
            燕潮燕潮小程序管理平台 &copy; {new Date().getFullYear()}{" "}
          </Typography>
        </Grid>
        <Grid item style={{ paddingLeft: 20 }}>
          <Link to="/login" className={classes.link}>
            <Typography variant="body2" className={classes.login}>
              后台登录
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </footer>
  );
}

export default BottomFooter;
