import { generalConstants } from "../_constants";

export const generalActions = {
  alertControl,
};

function alertControl(msg, alertType, callback = () => {}) {
  return (dispatch) => {
    if (msg === "") {
      dispatch(unshown());
    } else {
      dispatch(shown(msg, alertType));
    }
  };

  function shown() {
    return { type: generalConstants.ALERT_SHOWN, msg, alertType, callback };
  }
  function unshown() {
    return { type: generalConstants.ALERT_UNSHOWN, msg, alertType, callback };
  }
}
